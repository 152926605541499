import React, { useEffect, useState } from "react";

import { LinkModal } from "kh-common-components";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/Seo";

import LayoutMain from "../components/layout/layoutMain";
import Faq from '../components/common/Faq';
import Link from '../components/common/Link';

import ChooseKleenheatInfo from '../components/cards/ChooseKleenheatInfo';
import AwardsInfo from '../components/cards/AwardsInfo';

import flybuys20x from "../images/flybuys/20-per-1-spent.png";

import { modalContents } from "../constants/modal-content";
import faqDataOffer from '../constants/components/accordion-list/flybuys';

import "styles/pages/flybuys.css";

function Flybuys20xOffer() {

    const [promoCode, setPromoCode] = useState('');

	useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sourceQuery = urlParams.get('utm_medium');
        let promo = '';

        switch (sourceQuery) {
            case 'referral':
                promo = 'FLYBUYS20XWEB';
                break;

            case 'app':
                promo = 'FLYBUYS20XAPP';
                break;

            default:
                promo = 'FLYBUYS20XEDM';
                break;
        }

        setPromoCode(promo);
    }, []);

    return (
    <>
        <SEO title="Flybuys – Kleenheat" 
            description="Collect Flybuys points on your natural gas spend."
            meta={[{name:"robots", content:'noindex'}]}
        />

        <Header />
        <LayoutMain>
        
        <section className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg">
            <div className="container">
                <div className="row mer-panel align-items-center justify-content-around">
                    <div className="col-10 col-sm-6 col-lg-7 offset-lg-1 order-2 order-sm-1 mer-text--size-lg">
                        <div className="content">
                            <h1>Switch and collect 20x Flybuys points on your natural gas spend.</h1>
                            <ul>
                                <li>Collect 20 Flybuys points per $1 spent on natural gas for 12 months.<sup>^</sup></li>
                                <li>Plus, save 35% off your natural gas usage charges for 12 months.<sup>*</sup></li>
                                <li>No sign-up fees, or lock-in contracts.</li>
                            </ul>
                            <p>Offer ends 30 September 2024.</p>

                            <div>
                                <small>
                                    <LinkModal
                                        modalContents={modalContents}
                                        useModal="true"
                                        displayContent={<span>Terms and conditions apply</span>}
                                        title="20x Flybuys points on natural gas spend"
                                        modalTitle="20x Flybuys points on natural gas spend"
                                        modalContentName="flybuys20x_terms_conditions"
                                        okayButtonLabel="Okay"
                                    />
                                </small>
                            </div>

                            <div className="mer-panel__actions mer-button-group">
                                <Link text="Switch now"
                                    link={`/sign-up/promo/${promoCode}`}
                                    linkClass="mer-button mer-button--primary" />
                            </div>
                        </div>
                    </div>
                    <div className="mer-panel__images hero-banner-flybuys col-8 col-sm-6 col-lg-4 order-1 order-sm-2 mt-0">
                        <img src={flybuys20x} 
                            className="mer-panel__image"
                            alt="Flybuys 20 points per dollar" />
                    </div>
                </div>
            </div>
        </section>

        <ChooseKleenheatInfo panelBackground="mer-bg--ui-light"
                        panelPadding="mer-pt-lg" />

        <AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    panelPadding="mer-py-de" />

        <Faq panelBackground="mer-bg--ui-light"
            panelPadding="mer-py-lg"
            id="faq"
            data={faqDataOffer}
        />

        <section id="sign-up" 
                    className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10 col-lg-7">
                    <div className="content text-center">
                        <div className="mer-text--size-lg">
                            <h2>Start collecting more points on your <span clasName="">natural gas spend</span>.</h2>
                            <p>Sign up online in a few easy steps.</p>
                            <a href={`/sign-up/promo/${promoCode}`}
                                className="mer-button mer-button--primary mb-3">
                                <span className="mer-color--gs-white">Sign up</span>
                            </a>
                        </div>
                    </div>
                    </div>
                </div>                
            </div>
        </section>
        </LayoutMain>
        <Footer />
    </>
    );
}

export default Flybuys20xOffer;
